import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";

function VerifyPayment() {


  const [modalShowUp, setModalShowUp] = React.useState(false);
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
    const auth = getAuth();
  
  
    //fetch all menu items
    useEffect(() => {     
              const fetchData = async () => {  
                const q = query(collection(db, 'Purchases'), orderBy('timestamp', 'desc'));
              let studentsItem = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                  studentsItem.push({ id: doc.id, ...doc.data() });
                  setStudentList([...studentsItem]);   
              });
      
          
          };
      fetchData();
      //end magic
  
      }, []);
      //end fetching menu items

      function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
      
        return formattedDate;
      }

      function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true // Use 12-hour format
        };
        const formattedDateTime = date.toLocaleDateString('en-US', options);
        const timeString = date.toLocaleTimeString('en-US', options);
      
        return `${formattedDateTime}`;
      }



      //delete buyer
    function deleteThisItem(docid){

      const q = query(collection(db, "expenses"), where("docid", "==", docid));
      getDocs(q).then((QuerySnapshot)=>{
          QuerySnapshot.forEach((thdoc)=>{
              let itemname = thdoc.data().firstName;
              console.log(itemname)

              swal({
                title: `Delete an Expense`,
                text: `Are you sure you want to delete this expense ?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  deleteDoc(doc(db, "expenses", docid))
                  .then(()=>{
                    swal("Deleted", "" , "success");
                    swal(`Expense has been deleted!`, {
                      icon: "success",
                    }).then(()=>{
                      window.location.reload();
                    })
                    
                  })

                } else {
                  swal("Cancelled");
                }
              });                    
          })
      })

  }


  return (
    <div className='verifypage'>

    <h1>All Orders Made</h1>

<Table striped bordered hover>
          <thead>
            <tr>
              <th>Date and time</th>
              <th>Customer Name</th>
              <th>Customer Phone</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Amount</th>
              <th>Delivery Location</th>
              <th>MPESA Code</th>
            </tr>
          </thead>
          <tbody>
            {studentsList.map((mystudent) => (
              <tr key={mystudent.id}>
                 <td>{mystudent.timestamp.toDate().toLocaleString()}</td>
                <td>{mystudent.customerName}</td>
                <td>{mystudent.phoneNumber}</td>
                <td>{mystudent.productName}</td>

                <td>{mystudent.quantity}</td>
                <td>{mystudent.totalAmount}</td>
                <td>{mystudent.deliveryLocation}</td>
                <td>{mystudent.transactionRef}</td>
                
              </tr>
            ))}
          </tbody>
        </Table>
    </div>
  )
}

export default VerifyPayment