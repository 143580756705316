// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7GU-vymylWBXaTtBjsHujgNGUywg8E9w",
  authDomain: "fishmania-80027.firebaseapp.com",
  projectId: "fishmania-80027",
  storageBucket: "fishmania-80027.appspot.com",
  messagingSenderId: "564128990280",
  appId: "1:564128990280:web:1c140455cfbba55cac847d",
  measurementId: "G-CR4W0DFFSK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app};
export {analytics}