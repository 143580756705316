import React, { useRef, useState, useEffect } from 'react';
import fish from '../Images/fish.png'
import omena from '../Images/omena.png'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {useNavigate } from "react-router-dom";
import MenuPage from '../Components/MenuPage.js'


function Home() {

    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
    const auth = getAuth();
    const storage = getStorage();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const q = query(
              collection(db, 'fishproducts'),
              orderBy('myorder', 'asc')
            );
            const querySnapshot = await getDocs(q);
            let studentsItem = [];
            querySnapshot.forEach((doc) => {
              studentsItem.push({ id: doc.id, ...doc.data() });
            });
            setStudentList(studentsItem);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, [db]);
    
        function buyNow(documentId){
          navigate('/buy-now', { state: { myData: documentId } });
        }
    
    
        const formatPrice = (price) => {
          // Convert price to a number if it’s not already
          const num = Number(price);
          // Check if the conversion resulted in NaN
          if (isNaN(num)) {
            return 'N/A'; // Fallback for invalid numbers
          }
          return num.toLocaleString();
        };

  return (
    <div className='mainPage'>

        <MenuPage/>

        <div className='heroDiv'>
            <div className='myopacity'></div>
            <img src="https://res.cloudinary.com/ubunifu/image/upload/v1720709014/bgimage_m2c2io.jpg" alt="" />
            <div className='mainPageCont'>
              <h2>Fresh From the Lake to Your Doorstep</h2>
              <p>Delivering the finest selection of fresh fish and seafood straight to your home.</p>
              <div>
                <button className='btnAbout'>About Us</button>
                <button className='btnContact'>Contact Us</button>
              </div>
            </div>
        </div>

        <h5>Our Products</h5>

        <div className='productDiv'>

        {studentsList.map((mystudent) => (
            <div className='prodDiv1' key={Math.random()} onClick={() => buyNow(mystudent.docID)}>
            <div className='prodDiv2'>
                <img src={mystudent.productImage} className='productImage' alt="" />
            </div>
            <h6>{mystudent.productName}</h6>
            <h4>KES.{formatPrice(mystudent.productPrice)} <span className="newSpan">{mystudent.perPiece}</span> </h4>
            <p>{mystudent.productDesc}</p>
            <button className='btn btn-sm btn-primary' onClick={() => buyNow(mystudent.docID)}>Buy now</button>
            </div>
        ))}


        </div>
    </div>
  )
}

export default Home