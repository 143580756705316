import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from './Pages/Home';
import Buynow from './Pages/Buynow';
import Success from './Pages/Success';
import VerifyPayment from './Pages/VerifyPayment';
import ContactUs from './Pages/ContactUs';

function App() {
  return (
    <div className="App">
      <BrowserRouter> 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/buy-now" element={<Buynow />} />
            <Route path="/success" element={<Success />} />

            <Route path="/verify" element={<VerifyPayment />} />
            <Route path="/contact-us" element={<ContactUs />} />
            
          </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default App;
