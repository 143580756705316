import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {useNavigate } from "react-router-dom";


function Success() {

    let navigate = useNavigate();

    function backHome(){
        navigate('/');
      }

  return (
    <div className='successPage'>

        <FontAwesomeIcon icon={faCheckCircle } className='checkCircle'/> 
        <h2>Order Request Submitted Successfully</h2>
        <p>The order Request has been successfully Received. We will get back to you</p>
        <button className='btn btn-primary' onClick={backHome}>Back Home</button>

    </div>
  )
}

export default Success