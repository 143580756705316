import React from 'react'
import MenuPage from '../Components/MenuPage'

function ContactUs() {
  return (
    <div>
        <MenuPage/>

        <div className='contactMain'>

            <div className='contactLeft'>
                <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.796224116207!2d36.78498187452895!3d-1.296929098690744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f110000ea60d3%3A0x41f900737ee06fe2!2sKamami&#39;s%20Fresh%20Fish!5e0!3m2!1sen!2ske!4v1721862442410!5m2!1sen!2ske"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className='contactRight'>
                <h2>Contact Us</h2>
                <p>Phone: +254 721 669538</p>
                <p>Email: Mercyowino1700@gmail.com</p>
                <p>Address: 53 park view Nairobi</p>


            </div>


        </div>

       
    </div>
  )
}

export default ContactUs