import React from 'react'
import logo from '../Images/kamamis.jpg'
import {useNavigate } from "react-router-dom";

function MenuPage() {

  let navigate = useNavigate();

  function goToContact(){
    navigate('/contact-us');
  }

  function goHome(){
    navigate('/');
  }

  return (
    <div className='menuPage'>

        <img src={logo} onClick={goHome} className='logo'/>

        <ul className='theMenuPage'>
            <li>About us</li>
            <li>Our Products</li>
            <li className='contactUs' onClick={goToContact}>Contact Us</li>
        </ul>
        <button className='contactUsNew' onClick={goToContact}>Contact Us</button>
    
    </div>
  )
}

export default MenuPage