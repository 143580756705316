import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { app } from '../firebase.js';
import { getFirestore, collection, query, getDocs, where, addDoc, doc, setDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';

function Buynow() {
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();

  const [theproductName, setproductName] = useState();
  const [theproductImage, setproductImage] = useState();
  const [theproductDesc, setproductDesc] = useState();
  const [theproductPrice, setproductPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [transactionRef, setTransactionRef] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState('Hurlingham');

  useEffect(() => {
    const data = location.state?.myData;
    setMyData(data);
  }, [location.state]);

  const [myData, setMyData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (myData) {
        try {
          const querySnapshot = await getDocs(query(collection(db, 'fishproducts'), where('docID', '==', myData)));
          querySnapshot.forEach((doc) => {
            const productName = doc.data().productName;
            const productImage = doc.data().productImage;
            const productDesc = doc.data().productDesc;
            const productPrice = doc.data().productPrice;

            setproductName(productName);
            setproductImage(productImage);
            setproductDesc(productDesc);
            setproductPrice(productPrice);
          });
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }
    };
    fetchData();
  }, [myData, db]);

  useEffect(() => {
    setTotalAmount(quantity * theproductPrice);
  }, [quantity, theproductPrice]);

  const formatPrice = (price) => {
    const num = Number(price);
    return isNaN(num) ? 'N/A' : num.toLocaleString();
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleMakePayment = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleTransactionRefChange = (e) => {
    setTransactionRef(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const newDataRef = doc(collection(db, "Purchases"));
    setDoc(newDataRef, {
      productName: theproductName,
      quantity,
      totalAmount,
      customerName,
      phoneNumber,
      deliveryLocation,
      transactionRef,
      timestamp: new Date(),
      documentId:newDataRef.id
    }).then(()=>{
      swal("Success", "Transaction submitted successfully", "success");
      navigate('/success'); // Redirect to a confirmation page or wherever appropriate
    });
    setIsLoading(false);
    setShowModal(false);
  };

  return (
    <div className='buyNow'>
      <div className='buyNowDiv1'>
        <img src={theproductImage} alt="" />
      </div>

      <div className='buyNowDiv2'>
        <h2>{theproductName}</h2>
        <p>{theproductDesc}</p>
        <h1>KES. {theproductPrice}</h1>
        <hr />
        <h3>Buy Now</h3>

        <Form.Group className="mb-3" controlId="quantity">
          <Form.Label>Type in the quantity</Form.Label>
          <Form.Control
            type="number"
            name="quantity"
            value={quantity}
            onChange={handleQuantityChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="customerName">
          <Form.Label>Customer Name</Form.Label>
          <Form.Control
            type="text"
            name="customerName"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="phoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="deliveryLocation">
          <Form.Label>Delivery Location</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={deliveryLocation}
            onChange={(e) => setDeliveryLocation(e.target.value)}
          >
            <option value="Hurlingham">Hurlingham</option>
            <option value="Kilimani">Kilimani</option>
            <option value="Ngong Road">Ngong Road</option>
            <option value="Kileleshwa">Kileleshwa</option>
            <option value="Lavington">Lavington</option>
            <option value="Westlands">Westlands</option>
          </Form.Select>
        </Form.Group>

        <div className='bookButtonCont'>
          <div>
            <p>Total Amount</p>
            <h6>KES. {formatPrice(totalAmount)}</h6>
          </div>
          <Button onClick={handleMakePayment} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span> Processing...</span>
              </>
            ) : (
              "Make Payment"
            )}
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>

           <div className='alert alert-primary'>
            Till Number: 4138131

           </div>
          <Form.Group className="mb-3" controlId="transactionRef">
            <Form.Label>M-PESA Transaction Code</Form.Label>
            <Form.Control
              type="text"
              value={transactionRef}
              onChange={handleTransactionRefChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span> Submitting...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Buynow;
